import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { User, UserSerializer } from "./user"
import { SellerGroup, SellerGroupSerializer } from "./seller-group"
import { JsonObject } from "../_core/types"
import { UserAuthenticationLog, UserAuthenticationLogSerializer } from "@app/_models/user-authentication-log"

export class Seller {
    id = 0
    createdAt: Date
    updatedAt: Date
    login: string
    name: string
    phone: string
    dob: Date
    email: string
    isFinanceAdmin: boolean = false
    role: number
    superiorSellerId: number
    superiorSeller: Seller
    newPassword = ""
    permissions: Array<number>
    user: User
    authenticationHistory: UserAuthenticationLog[] | null
    status: number
    statusLabel: string
    languageId: number
    currencyId: number
    sellerGroupId: number
    sellerGroup: SellerGroup
    password?: string
    passwordConfirmation?: string
    currentPassword?: string
    partnerId: number
    isIndividualSeller: boolean
    financeCommission: number = 100
    residualCommission: number = 100
    loyaltyCommission: number = 100
    isSiteVisitAdmin: boolean
}

@Injectable({
    providedIn: "root",
})
export class SellerSerializer implements ApiResourceSerializer {
    constructor(
        private userSerializer: UserSerializer,
        private sellerGroupSerializer: SellerGroupSerializer,
        private authenticationLogSerializer: UserAuthenticationLogSerializer,
    ) {}

    fromJson(json: JsonObject): Seller {
        const record = new Seller()
        record.id = json.id
        record.login = json.login
        record.createdAt = json.created_at ? new Date(json.created_at) : null
        record.updatedAt = json.updated_at ? new Date(json.updated_at) : null
        record.name = json.name
        record.phone = json.phone
        record.dob = json.dob ? new Date(json.dob) : null
        record.email = json.email
        record.isFinanceAdmin = json.is_finance_admin
        record.role = json.role
        record.superiorSellerId = json.superior_seller_id
        record.superiorSeller =
            json.superior_seller !== undefined && json.superior_seller !== null
                ? this.fromJson(json.superior_seller)
                : null

        record.permissions = json.permissions
        record.user = json.user !== undefined && json.user !== null ? this.userSerializer.fromJson(json.user) : null
        record.status = json.status
        record.statusLabel = json.status_label

        record.sellerGroupId = json.seller_group_id
        record.sellerGroup =
            json.seller_group !== undefined && json.seller_group !== null
                ? this.sellerGroupSerializer.fromJson(json.seller_group)
                : null
        record.authenticationHistory =
            json.authentication_history !== undefined
                ? json.authentication_history.map(item => this.authenticationLogSerializer.fromJson(item))
                : null
        record.partnerId = json.partner_id
        record.isIndividualSeller = json.is_individual_seller
        record.financeCommission = json.commissions?.finance ?? 100
        record.residualCommission = json.commissions?.residual ?? 100
        record.loyaltyCommission = json.commissions?.loyalty ?? 100
        record.isSiteVisitAdmin = json.is_site_visit_admin

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: Seller): any {
        return {
            id: record.id,
            created_at: record.createdAt,
            updated_at: record.updatedAt,
            login: record.login,
            name: record.name,
            phone: record.phone,
            dob: record.dob,
            email: record.email,
            is_finance_admin: record.isFinanceAdmin,
            role: record.role,
            superior_seller:
                record.superiorSeller !== undefined && record.superiorSeller !== null
                    ? this.toJson(record.superiorSeller)
                    : null,
            permissions: record.permissions,
            superior_seller_id: record.superiorSellerId,
            user: record.user !== undefined && record.user !== null ? this.userSerializer.toJson(record.user) : null,
            status: record.status,
            status_label: record.statusLabel,
            seller_group_id: record.sellerGroupId,
            seller_group:
                record.sellerGroup !== undefined && record.sellerGroup !== null
                    ? this.sellerGroupSerializer.toJson(record.sellerGroup)
                    : null,
            authentication_history: record.authenticationHistory
                ? record.authenticationHistory.map(item => this.authenticationLogSerializer.toJson(item))
                : [],
            partner_id: record.partnerId,
            password: record.password,
            password_confirmation: record.passwordConfirmation,
            current_password: record.currentPassword,
            finance_commission: record.financeCommission,
            residual_commission: record.residualCommission,
            loyalty_commission: record.loyaltyCommission,
            is_site_visit_admin: record.isSiteVisitAdmin,
        }
    }
}
