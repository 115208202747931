import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { PaypanelComponent } from "./paypanel.component"
import { MessageWhiteComponent } from "./message-white.component"
import { NotificationWhiteComponent } from "./notification-white.component"
import { SettingWhiteComponent } from "./setting-white.component"
import { CloseComponent } from "./close.component"
import { QuestionWhiteComponent } from "./question-white.component"
import { LogoutBlackComponent } from "./logout-black.component"
import { TransactComponent } from "./transact.component"
import { DeleteBlueComponent } from "./delete-blue.component"
import { ArrowDownComponent } from "./arrow-down.component"
import { MyPayLinksComponent } from "./my-pay-links.component"
import { SelectAllWhiteComponent } from "./select-all-white.component"
import { EditIconComponent } from "./edit-icon.component"
import { AdminIconComponent } from "./admin-icon.component"
import { ErrorExclamationIconComponent } from "./error-exclamation-icon.component"
import { SearchIconComponent } from "./search-icon.component"
import { ClipIconComponent } from "./clip-icon.component"
import { VPaymentIconComponent } from "./vpayment-icon.component"
import { PayByLinkComponent } from "./pay-by-link.component"
import { WarningComponentComponent } from "./warning-component.component"
import { ErrorComponentComponent } from "@app/_components/app-svg/error-component.component"
import { InfoIconComponent } from "@app/_components/app-svg/info-icon.component"
import { SuccessIconComponent } from "@app/_components/app-svg/success-icon.component"
import { CalendarIconComponent } from "./calender.component"
import { ThreeDotsIconComponent } from "./three-dots.component"
import { CopyIconComponent } from "./copy-icon.component"
import { VSupportLogoComponent } from "./vsupport-logo.component"
import { PoundSymbolComponent } from "./pound-symbol.component"
import { VerofyLogoMainComponent } from "./verofy-logo-main.component"
import { MessageInboxComponent } from "./message-inbox.component"
import { MessageArchiveComponent } from "./message-archive.component"
import { MessageInboxOpenComponent } from "./message-inbox-open.component"
import { EditIconBlackComponent } from "./edit-icon-black.component"
import { DeleteIconBlackComponent } from "./delete-icon-black.component"
import { QuestionMarkComponent } from "./question-mark.component"
import { HeadSetComponent } from "./head-set-icon"
import { PlusIconComponent } from "./plus-icon"
import { RemoveIconComponent } from "@appComponents/app-svg/remove-icon"
import { VerofyFooterLogoComponent } from "@appComponents/app-svg/verofy-footer-logo"
import { ToggleArrowComponent } from "@appComponents/app-svg/toggle-arrow.component"
import { DownloadComponent } from "@appComponents/app-svg/download.component"
import { LockIconComponent } from "@appComponents/app-svg/lock-icon.component"

@NgModule({
    declarations: [
        PaypanelComponent,
        MessageWhiteComponent,
        NotificationWhiteComponent,
        SettingWhiteComponent,
        CloseComponent,
        QuestionWhiteComponent,
        LogoutBlackComponent,
        TransactComponent,
        DeleteBlueComponent,
        ArrowDownComponent,
        MyPayLinksComponent,
        SelectAllWhiteComponent,
        EditIconComponent,
        AdminIconComponent,
        ErrorExclamationIconComponent,
        SearchIconComponent,
        ClipIconComponent,
        VPaymentIconComponent,
        PayByLinkComponent,
        WarningComponentComponent,
        ErrorComponentComponent,
        InfoIconComponent,
        SuccessIconComponent,
        CalendarIconComponent,
        ThreeDotsIconComponent,
        CopyIconComponent,
        VSupportLogoComponent,
        PoundSymbolComponent,
        VerofyLogoMainComponent,
        MessageInboxComponent,
        MessageArchiveComponent,
        MessageInboxOpenComponent,
        EditIconBlackComponent,
        DeleteIconBlackComponent,
        QuestionMarkComponent,
        HeadSetComponent,
        PlusIconComponent,
        RemoveIconComponent,
        VerofyFooterLogoComponent,
        ToggleArrowComponent,
        DownloadComponent,
        LockIconComponent,
    ],
    imports: [CommonModule],
    exports: [
        PaypanelComponent,
        MessageWhiteComponent,
        NotificationWhiteComponent,
        SettingWhiteComponent,
        CloseComponent,
        QuestionWhiteComponent,
        LogoutBlackComponent,
        TransactComponent,
        DeleteBlueComponent,
        ArrowDownComponent,
        MyPayLinksComponent,
        SelectAllWhiteComponent,
        EditIconComponent,
        AdminIconComponent,
        ErrorExclamationIconComponent,
        SearchIconComponent,
        ClipIconComponent,
        VPaymentIconComponent,
        PayByLinkComponent,
        WarningComponentComponent,
        ErrorComponentComponent,
        InfoIconComponent,
        SuccessIconComponent,
        CalendarIconComponent,
        ThreeDotsIconComponent,
        CopyIconComponent,
        VSupportLogoComponent,
        PoundSymbolComponent,
        VerofyLogoMainComponent,
        MessageInboxComponent,
        MessageArchiveComponent,
        MessageInboxOpenComponent,
        EditIconBlackComponent,
        DeleteIconBlackComponent,
        QuestionMarkComponent,
        HeadSetComponent,
        PlusIconComponent,
        RemoveIconComponent,
        VerofyFooterLogoComponent,
        ToggleArrowComponent,
        DownloadComponent,
        LockIconComponent,
    ],
})
export class AppSvgModule {}
