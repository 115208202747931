import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { CustomerLocation, CustomerLocationSerializer } from "./customer-location"
import { SystemFile, SystemFileSerializer } from "./file"
import { Currency, CurrencySerializer } from "./currency"
import { Language, LanguageSerializer } from "./language"
import { CustomerExternalData, CustomerExternalDataSerializer } from "./customer-external-data"
import { Seller, SellerSerializer } from "./seller"
import { CustomerMid, CustomerMidSerializer } from "./customer-mid"
import { CustomerContract, CustomerContractSerializer } from "./customer-contract"
import { CustomerProduct, CustomerProductSerializer } from "./customer-product"
import { CustomerAddress, CustomerAddressSerializer } from "./customer-address"
import { JsonObject } from "../_core/types"
import { CustomerApplicant, CustomerApplicantSerializer } from "./customer-applicant"
import { MccCode, MccCodeSerializer } from "./MccCode"
import { CustomerDeliveryPackage, CustomerDeliveryPackageSerializer } from "./customer-delivery-package"

export class Customer {
    auth_key: string // just helper for a save

    id: number
    createdAt: Date
    updatedAt: Date

    name: string
    customerPublicKey: string
    legalName: string
    tradingName: string
    isLegalNameIdentical: boolean
    webhookUrl: string

    businessType: number
    registeredNumber: string
    vatNumber: string
    ownershipType: number
    websiteUrl: string
    settlementReportEmail: string
    registrationNumber: string

    mccCodeId: number
    mccCode: MccCode
    businessPhone: string
    businessDesc: string
    businessEstablishmentYear: number
    businessEstablishmentMonth: number
    lengthOfOwnershipYears: number
    lengthOfOwnershipMonths: number
    previousAcquirer: string
    estAnnualRevenue: number
    estTransactionsPerYear: number

    languageId: number = null
    language: Language
    currencyId: number = null
    currency: Currency
    mids: CustomerMid[]

    status: number
    statusLabel: string

    overallStatus: number
    overallStatusLabel: string

    // Primary (PRINCIPAL) CONTACT
    primaryContactTitle: string
    primaryContactFirstName: string
    primaryContactLastName: string
    primaryContactEmail: string
    primaryContactPhone: string
    primaryContactGender: string
    primaryContactDob: Date

    // Primary banking details
    primaryBankAccountName: string
    primaryBankSortCode: string
    primaryBankAccountNumber: string
    primaryBankIban: string
    primaryBankBic: string
    primaryBankName: string
    havingIban: boolean

    applicants: CustomerApplicant[]
    addresses: CustomerAddress[]
    locations: CustomerLocation[]
    users

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentPublicKey: any // toDo

    partnerId: number
    sellerId: number
    seller: Seller
    contracts: CustomerContract[]
    products: CustomerProduct[]

    // Files
    logo: SystemFile
    facePhoto: SystemFile
    idPassport: SystemFile
    proofAddress: SystemFile
    proofBanking: SystemFile
    paymentGatewayCss: SystemFile
    otherFiles: SystemFile[]

    externalData: CustomerExternalData
    businessAddress: CustomerAddress
    legalAddress: CustomerAddress
    homeAddress: CustomerAddress

    w2CompanyId: string
    isFaceToFaceDeal: boolean
    smsSentAt: Date
    smsVerifiedAt: Date
    messageForShareholders: string
    isSiteVisitRequired: boolean
    isSdiModeActive: boolean
    preRegistrationId: number

    lastTransacted: Date
    deliveryPackages: CustomerDeliveryPackage[]

    hasLiveProducts: boolean

    pricingTemplateType: string
    isCashbackEnabled: boolean
    hasLoyaltyBonus: boolean
    totalLoyaltyPayableToCustomer: number
    totalLoyaltyPayableToPartner: number
    loyaltyDuration: number
    hasTopup: boolean
    totalTopupAmount: number

    public static get titleOptions(): { id: string; name: string }[] {
        return [
            { id: "MR", name: "Mr" },
            { id: "MRS", name: "Mrs" },
            { id: "MRS", name: "Mrs" },
            { id: "MS", name: "Ms" },
            { id: "MX", name: "Mx" },
        ]
    }

    /**
     * Get concatenated customer full name
     */

    public getBreadCrumbLabel(): string {
        const legalNameLower = this.legalName.toLowerCase()
        const tradingNameLower = this.tradingName ? this.tradingName.toLowerCase() : ""

        if (legalNameLower === tradingNameLower) {
            return this.legalName
        } else {
            return this.legalName + (this.tradingName ? " - " + this.tradingName : "")
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerSerializer implements ApiResourceSerializer {
    constructor(
        private customerApplicantSerializer: CustomerApplicantSerializer,
        private customerAddressSerializer: CustomerAddressSerializer,
        private customerLocationSerializer: CustomerLocationSerializer,
        private systemFileSerializer: SystemFileSerializer,
        private currencySerializer: CurrencySerializer,
        private languageSerializer: LanguageSerializer,
        private customerExternalDataSerializer: CustomerExternalDataSerializer,
        private sellerSerializer: SellerSerializer,
        private customerMidSerializer: CustomerMidSerializer,
        private customerContractSerializer: CustomerContractSerializer,
        private customerProductSerializer: CustomerProductSerializer,
        private mccCodeSerializer: MccCodeSerializer,
        private customerDeliveryPackageSerializer: CustomerDeliveryPackageSerializer,
    ) {}

    fromJson(json: JsonObject): Customer {
        const customer = new Customer()
        customer.id = json.id
        customer.createdAt =
            json.created_at !== undefined && json.created_at !== null ? new Date(json.created_at) : null
        customer.updatedAt =
            json.updated_at !== undefined && json.updated_at !== null ? new Date(json.updated_at) : null
        customer.name = json.name
        customer.customerPublicKey = json.customer_public_key
        customer.legalName = json.legal_name
        customer.tradingName = json.trading_name
        customer.isLegalNameIdentical = json.is_legal_name_identical === 1
        customer.businessType = json.business_type
        customer.registeredNumber = json.registration_number
        customer.vatNumber = json.vat_number
        customer.ownershipType = json.ownership_type
        customer.websiteUrl = json.website_url
        customer.webhookUrl = json.webhook_url
        customer.settlementReportEmail = json.settlement_report_email
        customer.registrationNumber = json.registration_number
        customer.mccCodeId = json.mcc_code_id
        customer.mccCode =
            json.mcc_code !== undefined && json.mcc_code !== null
                ? this.mccCodeSerializer.fromJson(json.mcc_code)
                : null
        customer.businessPhone = json.business_phone
        customer.businessDesc = json.business_desc
        customer.businessEstablishmentYear = json.business_establishment_year
        customer.businessEstablishmentMonth = json.business_establishment_month
        customer.lengthOfOwnershipYears = json.length_of_ownership_years
        customer.lengthOfOwnershipMonths = json.length_of_ownership_months
        customer.previousAcquirer = json.previous_acquirer
        customer.estAnnualRevenue = json.est_annual_revenue
        customer.estTransactionsPerYear = json.est_transactions_per_year
        customer.languageId = json.language !== undefined && json.language !== null ? json.language.id : null
        customer.language =
            json.language !== undefined && json.language !== null
                ? this.languageSerializer.fromJson(json.language)
                : null
        customer.currencyId = json.currency !== undefined && json.currency !== null ? json.currency.id : null
        customer.currency =
            json.currency !== undefined && json.currency !== null
                ? this.currencySerializer.fromJson(json.currency)
                : null
        customer.mids =
            json.mids !== undefined && json.mids !== null
                ? json.mids.map(item => this.customerMidSerializer.fromJson(item))
                : []
        customer.primaryBankAccountName = json.primary_bank_account_name
        customer.primaryBankSortCode = json.primary_bank_sort_code
        customer.primaryBankAccountNumber = json.primary_bank_account_number
        customer.primaryBankIban = json.primary_bank_iban
        customer.primaryBankBic = json.primary_bank_bic
        customer.primaryBankName = json.primary_bank_name
        customer.primaryContactTitle = json.primary_contact_title
        customer.primaryContactFirstName = json.primary_contact_first_name
        customer.primaryContactLastName = json.primary_contact_last_name
        customer.primaryContactDob =
            json.primary_contact_dob !== undefined && json.primary_contact_dob !== null
                ? new Date(json.primary_contact_dob)
                : null
        customer.primaryContactGender = json.primary_contact_gender
        customer.primaryContactEmail = json.primary_contact_email
        customer.primaryContactPhone = json.primary_contact_phone
        customer.havingIban = json.primary_bank_iban !== null
        customer.status = json.status
        customer.statusLabel = json.status_label
        customer.overallStatus = json.overall_status
        customer.overallStatusLabel = json.overall_status_label
        customer.applicants =
            json.applicants !== undefined
                ? json.applicants.map(item => this.customerApplicantSerializer.fromJson(item))
                : []
        customer.addresses =
            json.addresses !== undefined
                ? json.addresses.map(item => this.customerAddressSerializer.fromJson(item))
                : []
        customer.locations =
            json.locations !== undefined
                ? json.locations.map(item => this.customerLocationSerializer.fromJson(item))
                : []
        customer.users = json.users
        customer.partnerId = json.partner_id
        customer.sellerId = json.seller_id
        customer.seller =
            json.seller !== undefined && json.seller !== null ? this.sellerSerializer.fromJson(json.seller) : null
        customer.contracts =
            json.contracts !== undefined && json.contracts !== null
                ? json.contracts.map(item => this.customerContractSerializer.fromJson(item))
                : []
        customer.products =
            json.products !== undefined && json.products !== null
                ? json.products.map(item => this.customerProductSerializer.fromJson(item))
                : []

        // Files
        customer.logo =
            json.logo !== undefined && json.logo !== null ? this.systemFileSerializer.fromJson(json.logo) : null
        customer.facePhoto =
            json.face_photo !== undefined && json.face_photo !== null
                ? this.systemFileSerializer.fromJson(json.face_photo)
                : null
        customer.idPassport =
            json.id_passport !== undefined && json.id_passport !== null
                ? this.systemFileSerializer.fromJson(json.id_passport)
                : null
        customer.proofAddress =
            json.proof_address !== undefined && json.proof_address !== null
                ? this.systemFileSerializer.fromJson(json.proof_address)
                : null
        customer.proofBanking =
            json.proof_banking !== undefined && json.proof_banking !== null
                ? this.systemFileSerializer.fromJson(json.proof_banking)
                : null

        customer.paymentGatewayCss =
            json.payment_gateway_css !== undefined && json.payment_gateway_css !== null
                ? this.systemFileSerializer.fromJson(json.payment_gateway_css)
                : null
        customer.otherFiles =
            json.other_files !== undefined && json.other_files !== null
                ? json.other_files.map(item => this.systemFileSerializer.fromJson(item))
                : null
        customer.externalData =
            json.external_data !== undefined && json.external_data !== null
                ? this.customerExternalDataSerializer.fromJson(json.external_data)
                : null
        customer.w2CompanyId = json.w2_company_id
        customer.isFaceToFaceDeal = json.is_face_to_face_deal
        customer.businessAddress = customer.addresses?.find(item => item.type.includes(1))
        customer.homeAddress = customer.addresses?.find(item => item.type.includes(4))

        customer.smsSentAt = json.sms_sent_at ? new Date(json.sms_sent_at) : null
        customer.smsVerifiedAt = json.sms_verified_at ? new Date(json.sms_verified_at) : null
        customer.messageForShareholders = json.message_for_shareholders
        customer.isSiteVisitRequired = json.is_site_visit_required
        customer.isSdiModeActive = json.is_sdi_mode_active
        customer.preRegistrationId = json.pre_registration_id

        customer.lastTransacted = json.last_transacted ? new Date(json.last_transacted) : null
        customer.deliveryPackages = json.delivery_packages
            ? json.delivery_packages.map(item => this.customerDeliveryPackageSerializer.fromJson(item))
            : []

        customer.hasLiveProducts = json.has_live_products

        customer.pricingTemplateType = json.pricing_template_type
        customer.isCashbackEnabled = json.is_cashback_enabled
        customer.hasLoyaltyBonus = json.has_loyalty_bonus
        customer.totalLoyaltyPayableToCustomer = json.total_loyalty_payable_to_customer
        customer.totalLoyaltyPayableToPartner = json.total_loyalty_payable_to_partner
        customer.loyaltyDuration = json.loyalty_duration
        customer.hasTopup = json.has_topup
        customer.totalTopupAmount = json.total_topup_amount

        return customer
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(customer: Customer): any {
        return {
            id: customer.id,
            name: customer.name,
            legal_name: customer.legalName,
            trading_name: customer.tradingName,
            is_legal_name_identical: customer.isLegalNameIdentical ? 1 : 0,
            registration_number: customer.registeredNumber,
            vat_number: customer.vatNumber,
            ownership_type: customer.ownershipType,
            settlement_report_email: customer.settlementReportEmail,
            business_type: customer.businessType,
            mcc_code_id: customer.mccCodeId,
            website_url: customer.websiteUrl,
            webhook_url: customer.webhookUrl,
            business_phone: customer.businessPhone,
            business_desc: customer.businessDesc,
            business_establishment_year: customer.businessEstablishmentYear,
            business_establishment_month: customer.businessEstablishmentMonth,
            length_of_ownership_years: customer.lengthOfOwnershipYears,
            length_of_ownership_months: customer.lengthOfOwnershipMonths,
            previous_acquirer: customer.previousAcquirer,
            est_annual_revenue: customer.estAnnualRevenue,
            est_transactions_per_year: customer.estTransactionsPerYear,
            language_id: customer.languageId,
            currency_id: customer.currencyId,
            primary_bank_account_name: customer.primaryBankAccountName,
            primary_bank_sort_code: customer.primaryBankSortCode,
            primary_bank_account_number: customer.primaryBankAccountNumber,
            primary_bank_iban: customer.primaryBankIban,
            primary_bank_bic: customer.primaryBankBic,
            primary_bank_name: customer.primaryBankName,
            primary_contact_title: customer.primaryContactTitle,
            primary_contact_first_name: customer.primaryContactFirstName,
            primary_contact_last_name: customer.primaryContactLastName,
            primary_contact_dob: customer.primaryContactDob,
            primary_contact_gender: customer.primaryContactGender,
            primary_contact_email: customer.primaryContactEmail,
            primary_contact_phone: customer.primaryContactPhone,
            external_data: this.customerExternalDataSerializer.toJson(customer.externalData),
            business_address:
                customer.businessAddress !== undefined
                    ? this.customerAddressSerializer.toJson(customer.businessAddress)
                    : null,
            home_address:
                customer.homeAddress !== undefined ? this.customerAddressSerializer.toJson(customer.homeAddress) : null,
            legal_address: customer.legalAddress ? this.customerAddressSerializer.toJson(customer.legalAddress) : null,
            w2_company_id: customer.w2CompanyId,
            pre_registration_id: customer.preRegistrationId,
            has_live_products: customer.hasLiveProducts,
            pricing_template_type: customer.pricingTemplateType,
            is_cashback_enabled: customer.isCashbackEnabled,
            has_loyalty_bonus: customer.hasLoyaltyBonus,
            total_loyalty_payable_to_customer: customer.totalLoyaltyPayableToCustomer,
            total_loyalty_payable_to_partner: customer.totalLoyaltyPayableToPartner,
            loyalty_duration: customer.loyaltyDuration,
            has_topup: customer.hasTopup,
            total_topup_amount: customer.totalTopupAmount,
        }
    }
}
